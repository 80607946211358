import $ from "jquery";
import "jqtree/tree.jquery";
import Modal from "bootstrap5/js/dist/modal";
import "bootstrap";

const deleteLeaf = (url, onDelete) => {
  const el = document.getElementById('modal-delete');
  const form = document.getElementById('delete-form');
  const modal = Modal.getOrCreateInstance(el);
  modal.show();
  el.querySelector('#modal-delete-button').onclick = async e => {
    e.preventDefault();
    form.setAttribute('action', url);
    form.submit();
  }
}

$('[data-component="page-struct"]').each(function () {
  const $this = $(this);
  $(this)
      .tree({
        data: $(this).data('tree'),
        dragAndDrop: true,
        autoOpen: true,
        closedIcon: $('<i class="material-icons">chevron_right</i>'),
        openedIcon: $('<i class="material-icons">expand_more</i>'),
        onCanMove: node => true,
        onCanMoveTo: (moved_node, target_node, position) => true,
        onIsMoveHandle: function($element) {
          return ($element.is('.jqtree-title .drag-handler'));
        },
        onCreateLi: (node, $li) => {
          const $el = $li
              .find('.jqtree-element');
          const $title = $li
              .find('.jqtree-title')

          const $firstContainer = $(`<div class="first-container d-flex" data-original-url="${node.url}"><span class="page-title">${$title.text()}</span></div>`);
          const $secondContainer = $(`<div class="second-container d-flex align-items-center"></div>`);
          $title.text('')
          $title.append($firstContainer);
          $title.append($secondContainer);

          if (node.url) {
            $firstContainer.find('.page-title').append(` <span class="page-url">(${node.isMain ? '/' : node.url})</span>`);
          }

          if (node.isMain) {
            $firstContainer.prepend('<i class="material-icons home-icon">home</i>');
          }

          $firstContainer.prepend('<i class="material-icons drag-handler">drag_indicator</i>');

          $secondContainer.append(`<span class="page-type">${node.type}</span>`);

          $secondContainer.append(`<div class="d-flex actions-container">
                                    <div class="url-options">
                                        <i class="material-icons copy-url" data-copy="${node.isMain ? '/' : node.url}">content_copy</i>
                                        <a href="${node.isMain ? '/' : node.url}" target="_blank" class="d-flex go-to-url"><i class="material-icons">open_in_new</i></a>
                                    </div>
                                    <a href="${node.actions.edit}" class="btn btn-sm btn-outline-primary btn-primary-o-clock btn-sm-o-clock me-2 border-1 d-flex edit-button">
                                        <span class="material-icons">mode_edit</span><span class="edit-text">Edytuj</span>
                                    </a>
                                </div>`);

          $secondContainer.append(`<div class="dropdown o-clock-dropdown d-flex">
                        <i class="material-icons page-menu" type="button" data-toggle="dropdown">more_vert</i>
                        <ul class="dropdown-menu">
                          <li class="change-page-status">
                            <div class="d-flex" data-url="${node.actions.toggleStatus}">
                                <span class="material-icons">${node.status === 'enabled' ?  'visibility_off' : 'visibility'}</span>
                                <span class="text">${node.status === 'enabled' ?  'Dezaktywuj' : 'Aktywuj'}</span>
                            </div>
                          </li>
                          <li class="additional-options">
                            <a href="${node.actions.edit}" class="d-flex edit-button">
                                <span class="material-icons">mode_edit</span><span class="edit-text text">Edytuj</span>
                            </a>    
                          </li>
                          <li class="additional-options">
                            <div class="d-flex">
                                <span class="material-icons copy-url" data-copy="${node.isMain ? '/' : node.url}">content_copy</span>
                                <span class="text">Kopiuj URL</span>
                            </div>
                          </li>
                          <li class="additional-options">
                            <a href="${node.isMain ? '/' : node.url}" target="_blank" class="d-flex go-to-url">
                                <span class="material-icons">open_in_new</span>
                                <span class="text">Otwórz w nowej karcie</span>
                            </a>
                          </li>
                          <li class="delete-page ${ node.isMain ? 'd-none' : '' }">
                            <div class="d-flex" data-url="${node.actions.delete}">
                              <span class="material-icons">delete</span>
                              <span class="text">Usuń</span>
                            </div>
                          </li>
                        </ul>
                      </div>`);

          if (node.status === 'enabled') {
            $title.find('.mask').remove();
            $title.find('.o-clock-dropdown').removeClass('disabled');
          } else {
            $title.append('<div class="mask" style="position: absolute; height: 60px; opacity: 0.5; background: #fff"></div>');
            $title.find('.o-clock-dropdown').addClass('disabled');
            $secondContainer.find('.edit-button').css({
              'z-index': 1,
              'opacity': 0.5,
              'background': '#fff'
            });
          }

          $el.on('click', '.change-page-status', function () {
            const self = this;
            fetch($(this).find('div').data('url')).then((response) => {
              if (response.redirected) {
                window.location.href = response.url;
              }

              node.status = node.status === 'enabled' ? 'disabled' : 'enabled';
              $(self).find('span.material-icons').text(node.status === 'enabled' ? 'visibility_off' : 'visibility');
              $(self).find('span.text').text(node.status === 'enabled' ? 'Dezaktywuj' : 'Aktywuj');
              if (node.status === 'enabled') {
                $title.find('.mask').remove();
                $title.find('.o-clock-dropdown').removeClass('disabled');
                $secondContainer.find('.edit-button').css({
                  'z-index': 0,
                  'opacity': 1,
                  'background': '#fff'
                });
              } else {
                $title.append('<div class="mask" style="position: absolute; height: 60px; opacity: 0.5; background: #fff"></div>');
                $title.find('.o-clock-dropdown').addClass('disabled');
                $secondContainer.find('.edit-button').css({
                  'z-index': 1,
                  'opacity': 0.5,
                  'background': '#fff'
                });
              }

              toggleChildrenStatus(node.children, node.status);
            });
          });

          $el.on('click', '.delete-page', function () {
            deleteLeaf($(this).find('div').data('url'), () => $this.tree('removeNode', node));
          });

          $secondContainer.on('click', '.copy-url', function (e) {
            e.preventDefault();
            copyToClipboard(`${window.location.origin}${$(this).attr('data-copy')}`);
          });
        }
      })
      .on('tree.move', async e => {
        e.preventDefault();
        const formData = new FormData;
        formData.append('node', e.move_info.moved_node.id);
        formData.append('position', e.move_info.position);
        formData.append('previousParent', e.move_info.previous_parent.id);
        formData.append('target', e.move_info.target_node.id);
        const response = await fetch($(this).data('updateUrl'), {
          'method': 'POST',
          'body': formData
        });

        if (response.redirected) {
          window.location.href = response.url;
        }

        const responseData = await response.json();
        if (responseData.status === 'OK') {
          e.move_info.do_move();
        }

        $('.jqtree-tree').find('li.jqtree_common div.jqtree-element').each(function (index) {
          if (index === 0) {
            if ($(this).find('.first-container .home-icon').length === 0) {
              $('<i class="material-icons home-icon">home</i>').insertAfter($(this).find('.first-container .drag-handler'));
            }

            $(this).find('.first-container .page-url').text('(/)');
            $(this).find('.second-container .copy-url').attr('data-copy', '/');
            $(this).find('.second-container .go-to-url').attr('href', '/');

            $(this).find('.o-clock-dropdown .delete-page ').addClass('d-none');
          } else {
            const originalUrl = $(this).find('.first-container').attr('data-original-url');
            $(this).find('.home-icon').remove();
            $(this).find('.first-container .page-url').text(`(${originalUrl})`);
            $(this).find('.second-container .copy-url').attr('data-copy', originalUrl);
            $(this).find('.second-container .go-to-url').attr('href', originalUrl);
            $(this).find('.o-clock-dropdown .delete-page ').removeClass('d-none');
          }
        })

      })
});

function toggleChildrenStatus(children, status) {
  children.forEach(function (item) {
    const $element = $(item.element);
    const $title = $element.find('.jqtree-title');
    const $secondContainer = $element.find('.second-container');

    $secondContainer.find('.o-clock-dropdown .change-page-status span.material-icons').text(status === 'enabled' ? 'visibility_off' : 'visibility');
    $secondContainer.find('.o-clock-dropdown .change-page-status span.text').text(status === 'enabled' ? 'Dezaktywuj' : 'Aktywuj');

    if (status === 'enabled') {
      $title.find('.mask').remove();
      $title.find('.o-clock-dropdown').removeClass('disabled');
      $secondContainer.find('.edit-button').css({
        'z-index': 0,
        'opacity': 1,
        'background': '#fff'
      });
    } else {
      if ($title.find('.mask').length === 0) {
        $title.append('<div class="mask" style="position: absolute; height: 60px; opacity: 0.5; background: #fff"></div>');
        $title.find('.o-clock-dropdown').addClass('disabled');
        $secondContainer.find('.edit-button').css({
          'z-index': 1,
          'opacity': 0.5,
          'background': '#fff'
        });
      }
    }

    if (item.children.length > 0) {
      toggleChildrenStatus(item.children, status);
    }
  });
}

$('body').on('show.bs.dropdown', '.o-clock-dropdown', function () {
  $('.o-clock-dropdown').each(function () {
    $(this).css('z-index', 0);
  });

  $(this).css('z-index', 2);
});

$('body').on('hide.bs.dropdown', '.o-clock-dropdown', function () {
  $('.o-clock-dropdown').each(function () {
    $(this).css('z-index', 2);
  });
});

$('body').on('click', '.page-menu', function (e) {
  e.preventDefault();
});

function copyToClipboard(url) {
  const el = document.createElement('textarea');
  el.value = url;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

$(function () {
  $('#pages-type-filter').select2({
    width: '100%',
    placeholder: 'Wyszukaj po typie strony',
    dropdownCssClass: 'custom-dropdown-container',
    closeOnSelect: true,
  });
});

$('#pages_filter_search').on('click', function (e) {
  e.preventDefault();
  const search = $('#pages-title-filter').val();
  const pageType = $('#pages-type-filter').val();

  highlightText(search, $(".jqtree-tree .jqtree-title .first-container .page-title"));
  highlightText(pageType, $(".jqtree-tree .jqtree-title .second-container .page-type"));
});

$('#pages_filter_clear').on('click', function () {
  $('#pages-title-filter').val('');
  $('#pages-type-filter').val(null).trigger('change');

  $('#pages_filter_search').trigger('click');
});

function highlightText(search, $element) {
  const replaceD = "<span class='highlight'>" + search + "</span>";
  $element.each(function() {
    let text = $(this).text();
    text = text.replaceAll(search, replaceD);
    $(this).html(text);
  });
}
