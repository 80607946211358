import Modal from "bootstrap5/js/dist/modal";

const modal = document.getElementById('confirm-modal')
const modalTitle = modal.querySelector('h4');
const modalMessage = modal.querySelector('p');
const modalOkBtn = modal.querySelector('#confirm-modal-ok-button');
const modalOkBtnText = modalOkBtn.querySelector('span');
const modalCancelBtn = modal.querySelector('#confirm-modal-cancel-button');
const modalCancelBtnText = modalCancelBtn.querySelector('span');

window.confirm = function(message = modalMessage.dataset.default, {
  title = null,
  btnType = 'primary',
  okText = modalOkBtnText.dataset.default,
  cancelText = modalCancelBtnText.dataset.default
} = {}) {
  modalMessage.innerText = message;
  modalTitle.innerText = title;
  modalOkBtn.setAttribute('class', `btn btn-${btnType}`);
  modalOkBtnText.innerText = okText;
  modalCancelBtnText.innerText = cancelText;
  Modal.getOrCreateInstance(modal).show();
  return new Promise((resolve, reject) => {
    modalOkBtn.onclick = () => resolve();
    modalCancelBtn.onclick = () => reject();
  });
}

const confirm = el => e => {
  if (el.confirmModalConfirmed) return true;
  const data = el.dataset;
  window.confirm(data.confirmMessage, {
    title: data.confirmTitle,
    btnType: data.confirmBtnType,
    okText: data.confirmOkText,
    cancelText: data.confirmCancelText,
  }).then(function() {
    el.confirmModalConfirmed = true;
    el.click();
    el.confirmModalConfirmed = false;
  });
  return false;
}

const initConfirm = () => Array.from(document.querySelectorAll('[data-toggle=confirm]')).forEach(el => {
  el.onclick = confirm(el);
})

export default initConfirm;
