$('#rejectReasonChoices').on('change', function () {
    if ($(this).val() !== 'other') {
        $('#rejectReason').val($(this).val());
        $('#reject-button').removeAttr('disabled');
    } else {
        $('#rejectReason').val('');
        $('#reject-button').attr('disabled', true);
    }
});

$('#rejectReason').on('input', function () {
    if ($(this).val().length > 0) {
        $('#reject-button').removeAttr('disabled');
    } else {
        $('#reject-button').attr('disabled', true);
    }
});

$('[data-bs-target="#reject"]').on('click', function() {
    $('#reject form').attr('action', $(this).attr('href'));
});