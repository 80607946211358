import $ from "jquery";
import "jqtree/tree.jquery";
import Modal from "bootstrap5/js/dist/modal";

const deleteLeaf = (url, onDelete) => {
  const el = document.getElementById('modal-delete');
  const form = document.getElementById('delete-form');
  const modal = Modal.getOrCreateInstance(el);
  modal.show();
  el.querySelector('#modal-delete-button').onclick = async e => {
    e.preventDefault();
    form.setAttribute('action', url);
    form.submit();
  }
}

$('[data-component="menu"]').each(function () {
  const $this = $(this);
  $(this)
      .tree({
        data: $(this).data('tree'),
        dragAndDrop: true,
        autoOpen: true,
        closedIcon: $('<i class="material-icons">chevron_right</i>'),
        openedIcon: $('<i class="material-icons">expand_more</i>'),
        onCanMove: node => node.parent !== null,
        onCanMoveTo: (moved_node, target_node, position) => !(target_node.level === 0 && (position === 'after' || position === 'before')),
        onIsMoveHandle: function($element) {
          return ($element.is('.jqtree-title .drag-handler'));
        },
        onCreateLi: (node, $li) => {
          const $el = $li
              .find('.jqtree-element');
          const $title = $li
              .find('.jqtree-title')

          const $firstContainer = $(`<div class="first-container d-flex" data-original-url="${node.url}"><span class="page-title">${$title.text()}</span></div>`);
          const $secondContainer = $(`<div class="second-container d-flex align-items-center"></div>`);
          $title.text('')
          $title.append($firstContainer);
          $title.append($secondContainer);

          if (node.url) {
            $firstContainer.find('.page-title').append(` <span class="page-url">(${node.url})</span>`);
          }

          $firstContainer.prepend('<i class="material-icons drag-handler">drag_indicator</i>');

          $secondContainer.append(`<div class="d-flex actions-container" style="${!node.level ? 'margin-right: 24px;' : ''}">
                                    <div class="url-options">
                                        <i class="material-icons copy-url" data-copy="${node.isMain ? '/' : node.url}">content_copy</i>
                                        <a href="${node.isMain ? '/' : node.url}" target="_blank" class="d-flex go-to-url"><i class="material-icons">open_in_new</i></a>
                                    </div>
                                    <a href="${node.actions.edit}" class="btn btn-sm btn-outline-primary btn-primary-o-clock btn-sm-o-clock me-2 border-1 d-flex edit-button">
                                        <span class="material-icons">mode_edit</span><span class="edit-text">Edytuj</span>
                                    </a>
                                </div>`);

          if (node.level) {
            $secondContainer.append(`<div class="dropdown o-clock-dropdown d-flex">
            <i class="material-icons page-menu" type="button" data-toggle="dropdown">more_vert</i>
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="change-page-status">
                <div class="d-flex" data-url="${node.actions.toggleStatus}">
                    <span class="material-icons">${node.status === 'enabled' ?  'visibility_off' : 'visibility'}</span>
                    <span class="text">${node.status === 'enabled' ?  'Dezaktywuj' : 'Aktywuj'}</span>
                </div>
              </li>
              <li class="additional-options">
                <a href="${node.actions.edit}" class="d-flex edit-button">
                    <span class="material-icons">mode_edit</span><span class="edit-text text">Edytuj</span>
                </a>    
              </li>
              <li class="additional-options">
                <div class="d-flex">
                    <span class="material-icons copy-url" data-copy="${node.isMain ? '/' : node.url}">content_copy</span>
                    <span class="text">Kopiuj URL</span>
                </div>
              </li>
              <li class="additional-options">
                <a href="${node.isMain ? '/' : node.url}" target="_blank" class="d-flex go-to-url">
                    <span class="material-icons">open_in_new</span>
                    <span class="text">Otwórz w nowej karcie</span>
                </a>
              </li>
              <li class="delete-page ${ node.isMain ? 'd-none' : '' }">
                <div class="d-flex" data-url="${node.actions.delete}">
                  <span class="material-icons">delete</span>
                  <span class="text">Usuń</span>
                </div>
              </li>
            </ul>
          </div>`);

            if (node.status === 'enabled') {
              $title.find('.mask').remove();
              $title.find('.o-clock-dropdown').removeClass('disabled');
            } else {
              $title.append('<div class="mask" style="position: absolute; height: 60px; opacity: 0.5; background: #fff"></div>');
              $title.find('.o-clock-dropdown').addClass('disabled');
              $secondContainer.find('.edit-button').css({
                'z-index': 1,
                'opacity': 0.5,
                'background': '#fff'
              });
            }

            $el.on('click', '.change-page-status', function () {
              node.status = node.status === 'enabled' ? 'disabled' : 'enabled';
              $(this).find('span.material-icons').text(node.status === 'enabled' ? 'visibility_off' : 'visibility');
              $(this).find('span.text').text(node.status === 'enabled' ? 'Dezaktywuj' : 'Aktywuj');
              if (node.status === 'enabled') {
                $title.find('.mask').remove();
                $title.find('.o-clock-dropdown').removeClass('disabled');
              } else {
                $title.append('<div class="mask" style="position: absolute; height: 60px; opacity: 0.5; background: #fff"></div>');
                $title.find('.o-clock-dropdown').addClass('disabled');
              }
              fetch($(this).find('div').data('url')).then((response) => {
                if (response.redirected) {
                  window.location.href = response.url;
                }
              });
            });

            $el.on('click', '.delete-page', function () {
              deleteLeaf($(this).find('div').data('url'), () => $this.tree('removeNode', node));
            });

            $secondContainer.on('click', '.copy-url', function (e) {
              e.preventDefault();
              copyToClipboard(`${window.location.origin}${$(this).attr('data-copy')}`);
            });
          }
        }
      })
      .on('tree.move', async e => {
        e.preventDefault();
        const formData = new FormData;
        formData.append('node', e.move_info.moved_node.id);
        formData.append('position', e.move_info.position);
        formData.append('previousParent', e.move_info.previous_parent.id);
        formData.append('target', e.move_info.target_node.id);
        const response = await fetch($(this).data('updateUrl'), {
          'method': 'POST',
          'body': formData
        });

        if (await response && response.redirected) {
          window.location.href = response.url;
        }

        const responseData = await response.json();

        if (responseData.status === 'OK') {
          e.move_info.do_move();
        }

      })
});

$('body').on('show.bs.dropdown', '.o-clock-dropdown', function () {
  $('.o-clock-dropdown').each(function () {
    $(this).css('z-index', 0);
  });

  $(this).css('z-index', 1);
});

$('body').on('hide.bs.dropdown', '.o-clock-dropdown', function () {
  $('.o-clock-dropdown').each(function () {
    $(this).css('z-index', 1);
  });
});

function copyToClipboard(url) {
  const el = document.createElement('textarea');
  el.value = url;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
