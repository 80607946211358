import moment from 'moment';
moment.locale('pl');
moment.relativeTimeThreshold('s', 60);
//moment.relativeTimeThreshold('ss', 0);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);
moment.relativeTimeThreshold('M', 12);

$('.date-diff-for-humans, .datetime-diff-for-humans, .date-diff-for-humans-tooltip, .datetime-diff-for-humans-tooltip').each(function() {
    let isDate = $(this).is('.date-diff-for-humans, .date-diff-for-humans-tooltip');
    let isTooltip = $(this).is('.date-diff-for-humans-tooltip, .datetime-diff-for-humans-tooltip');
    let fn = () => {
        let time = isDate ? moment($(this).attr('datetime')).startOf('day') : moment($(this).attr('datetime'));
        let diffTime = isDate ? moment().startOf('day') : moment();
        let value = time.from(diffTime);
        if (isDate) {
            if (time.diff(diffTime, 'days') == 1) {
                value = 'jutro';
            } else if (time.diff(diffTime, 'days') == -1) {
                value = 'wczoraj';
            } else if (time.diff(diffTime, 'days') == 0) {
                value = 'dzisiaj';
            }
        }
        if (isTooltip) {
            $(this).attr('data-original-title', value);
        } else {
            $(this).html(value);
        }
    }
    fn();
    setInterval(fn, 1000);
});