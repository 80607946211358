const getContents = (body, selector) => {
    const item = body.querySelector(selector);
    if (!item) {
        return '';
    }
    const html = item.innerHTML.trim();
    return html || '';
}

export const initAjaxAction = () => {

    const modal = document.getElementById('ajax_action');
    if (!modal) {
        return false;
    }

    const title = modal.querySelector('.modal-title');
    const body = modal.querySelector('.modal-body');
    const footer = modal.querySelector('.modal-footer');
    const html = body.innerHTML;
    const titleText = title.innerHTML;
    let url = null;

    document.querySelectorAll('[data-bs-target="#ajax_action"]').forEach(btn => {
        btn.addEventListener('click', async e => {
            const response = await fetch(btn.getAttribute('href'), {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (await response && response.redirected) {
                window.location.href = response.url;
            }

            body.innerHTML = await response.text();
            title.innerHTML = getContents(body, '[data-ajax-action="title"]');
            footer.innerHTML = getContents(body, '[data-ajax-action="footer"]');
            if (footer.innerHTML.length) {
                footer.classList.remove('d-none');
            }

            const modalSize = $(body).find('div[data-ajax-action="content"]').attr('data-modal-size') || 'modal-xl';
            $(modal).find('.modal-dialog').addClass(modalSize);

            let flashHtml = getContents(body, '[data-ajax-action="flash"]')
            let bodyHtml = getContents(body, '[data-ajax-action="content"] .card-body')
                || getContents(body, '[data-ajax-action="content"]');
            body.innerHTML = flashHtml + bodyHtml;

            document.querySelectorAll('[data-select-type="select2"]').forEach(select => {
                const placeholder = $(select).attr('data-placeholder') || 'Wybierz typ podstrony';

                $(select).select2({
                    width: '100%',
                    placeholder: placeholder,
                    dropdownCssClass: 'custom-dropdown-container',
                    closeOnSelect: true,
                    dropdownParent: $('#ajax_action')
                });
            });

            let toFilterItems = [];
            let filteredItems = [];

            if ($('[data-to-filter="true"]').length > 0) {
                toFilterItems = [];
                $('[data-to-filter="true"]').find('option').each(function () {
                    if ($(this).val()) {
                        toFilterItems.push({
                            id: $(this).val(),
                            text: $(this).text(),
                            type: $(this).attr('data-type')
                        })
                    }
                });

                $('[data-filter-on-change="true"]').on('change', function () {
                    const type = $(this).val();

                    filteredItems = toFilterItems.filter(function (item) {
                        return item.type === type;
                    });

                    $('[data-to-filter="true"]').select2('destroy');

                    $('[data-to-filter="true"] option').each(function () {
                        $(this).remove();
                    });

                    $('[data-to-filter="true"]').select2({
                        width: '100%',
                        placeholder: $('[data-to-filter="true"]').attr('data-placeholder') || 'Wybierz',
                        dropdownCssClass: 'custom-dropdown-container',
                        closeOnSelect: true,
                        data: filteredItems,
                        dropdownParent: $('#ajax_action')
                    });
                });
            }
        })
    })

    $(modal).on('change', '#page-type-select', function () {
        const descriptionTarget = $(this).find(':selected').attr('data-description');

        $('.page-type-label-container').addClass('d-none');
        $(descriptionTarget).removeClass('d-none');
    });

    modal.addEventListener('hidden.bs.modal', () => {
        console.warn('hidde.bs.modal');
        body.innerHTML = html;
        title.innerHTML = titleText;
        footer.classList.add('d-none');
    });

}
