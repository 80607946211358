// import { LettersLeft } from "./letters-left";
import $ from "jquery";
window.$ = $;
// import { validateForm } from "./validateForm";
// import { copyToClipboard } from "./copyToClipboard";
import "select2";
import { initAjaxAction } from "./ajax-action";
import {TomSelect} from './tom-select';
import initConfirm from "./confirm";
import "./reject-form";
// LettersLeft($)('[maxlength]');
// validateForm('form.ea-edit-form, form.ea-new-form');
// copyToClipboard();

require("mdb-ui-kit/js/mdb.min")
require("./cms/menu")
require("./cms/page-struct")
require("./cms/contentbuilder")
require("./sticky-scrollbar");
require("./date-time-diff-for-humans");
require("datatables.net-dt");

initAjaxAction();
initConfirm();

$('.translation-locale-select').on('change', function() {
    window.location = $(this).data('href').replace('xx', $(this).val());
});

$('body').find('.o-clock-input').each(function () {
    if ($(this).val()) {
        $(this).closest('.form-group').find('label').addClass('focused-label');
    }
});

$('body').on('focus', '.o-clock-input', function () {
    $(this).closest('.form-group').find('label').addClass('focused-label');
}).on('focusout', '.o-clock-input', function () {
    if (!$(this).val()) {
        $(this).closest('.form-group').find('label').removeClass('focused-label');
    }
});
$('[data-toggle=tooltip]').tooltip();

//disable default (Easy Admin) front validation feedback
document.addEventListener('DOMContentLoaded', () => {
    $('.ea-edit [type="submit"], .ea-new [type="submit"]').each(function() {
        $(this).replaceWith($(this).clone());
    });
    $('.ea-edit-form, .ea-new-form').attr('novalidate', true);
});