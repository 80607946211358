import $ from "jquery";

/**
 * Handle contentBuilder on Symfony form
 */
const ContentBuilderBundle = (function () {

  var cbUniqueID = '',
      input,
      iframeReady = false,
      isFullscreen = false;
  var iframe,
      contentBuilderIframe;
  var cbConfig = {
    'cbUniqueID': '',
    'breakpointGroup': {},
    'snippetDir': '',
    'breakpoints': {
      'mobile': 320,
      'tablet': 1023,
      'desktop': 1199
    },
    'contentBuilderConfig': {}
  };

  /**
   * Set height of iframe based on iframe content
   *
   * @param uniqueID
   * @param height
   */
  var setContentBuilderAutoHeight = function (uniqueID, height) {
    height = (parseInt(height) + 80);
    $('#iframe_' + uniqueID).css('height', height);
  };

  var triggerMediaManagerDialog = function (selEv) {
    var mmd = new MediaManagerDialog({
      'uid': cbUniqueID,
      'mediaType': 'image'
    });

    mmd.onSelectFile = function (event) {
      //this - mmd
      var target = event.currentTarget,
          browseItem = $(target).closest('.browse-item');
      var originalUrl = browseItem.find('.image_zoom_button').find('.btn').attr('href');
      var fileName = originalUrl.split('/');
      fileName = fileName[(fileName.length - 1)];
      var fakeUrl = Routing.generate('o4clock_media_upload_fake_original', {'fileName': fileName});
      var inp = jQuery(selEv.targetInput).val(fakeUrl);

      //autocheck
      var checkbox = $(selEv.targetInput).closest('#divImgLnk').find('#chkCrop');
      checkbox.prop("checked", true);
      // checkbox.closest('label').addClass('hide');

      mmd.close();
    };
    mmd.open();
  };

  /**
   * Linkable dialog
   *
   * @param element
   * @param params
   */
  var triggerLinkableDialog = function (element, params) {
    var ld = new LinkableDialog(params);

    ld.onBrowseSuccess = function (response) {
      if (typeof response === 'string') {
        response = $.parseJSON(response);
      }
      var _this = this;

      _this.linkBtn.parent().find('#txtLink').val(response.url);
      if (response.targetBlank) {
        _this.linkBtn.parent().find('#chkNewWindow').prop("checked", true);
      } else {
        _this.linkBtn.parent().find('#chkNewWindow').prop("checked", false);
      }

      $(element).attr('data-link-id', response.linkId);

      _this.linkModal.modal('hide');
    };

    ld.init();
  };

  /**
   * Trigger on iframe on init
   *
   * @returns {boolean}
   */
  var iframeIsLoaded = function () {
    if (iframeReady) {
      return false;
    }

    iframeReady = true;

    iframe = $('#iframe_' + cbUniqueID);
    contentBuilderIframe = iframe.get(0).contentWindow['ContentBuilderIframeBundle'];

    sendPreviousContentToIframe(function () {
      contentBuilderIframe.initContentBuilder(cbConfig);
    });

    updateRteToolbarPosition();
  };

  /**
   * Update rte-toolbar on iframe
   */
  var updateRteToolbarPosition = function () {
    setInterval(function () {
      var parentTop = $(window).scrollTop();
      var iframeTop = iframe.offset().top;

      var rteTop = Math.max(0, (parentTop - iframeTop + 100));
      contentBuilderIframe.updateRteToolbarPosition(rteTop);
    }, 1000);
  };

  var sendPreviousContentToIframe = function (callback) {
    var sHtml = input.val();
    contentBuilderIframe.insertHtml(sHtml, callback);
  };


  //region EVENTS
  var onBeforeSubmitForm = function () {
    //save before submit form

    //catch click on buttons
    /*var submitButtons = iframe.closest('form').find('button[type="submit"]');
    submitButtons.on('click', function () {
        var __this = $(this);
        iframe.closest('form').find('.cb-submit-buttons').remove();
    });*/

    $('.ea-edit, .ea-new').find('[type="submit"]').on('click', function () {
      var sHTML = contentBuilderIframe.getContentBuilderHtml().trim();
      input.val(sHTML.replace(/\s\s+/g, ' '));
    });

    iframe.closest('form').on('submit.saveContentBuilder', function(e) {
      if (!iframe.hasClass('saved')) {
        var $form = $(this);
        e.preventDefault();
        iframe.closest('form').find('.cb-submit-buttons').remove();
        var activeBtn = $(document.activeElement);
        if (activeBtn.length > 0) {
          var fakeSubmitBtn = $('<input type="hidden"/>').attr('name', activeBtn.attr('name')).attr('value', activeBtn.attr('value'));
          $form.prepend(fakeSubmitBtn);
        }

        contentBuilderIframe.saveContentBuilder(function (sHTML) {
          input.val(sHTML.replace(/\s\s+/g, ' '));
          iframe.addClass('saved');
          $form.off('submit.saveContentBuilder');
          $form.submit();
        });
      }
    });

    /*iframe.closest('form').on('submit', function (e) {
      if (!iframe.hasClass('saved')) {

        iframe.closest('form').find('.cb-submit-buttons').remove();
        var activeBtn = $(document.activeElement);
        if (activeBtn.length > 0) {
          var fakeSubmitBtn = $('<input />').addClass('cb-submit-buttons').attr('name', activeBtn.attr('name')).val('1');
          iframe.closest('form').prepend(fakeSubmitBtn);
        }

        e.preventDefault();
        var form = $(this);

        contentBuilderIframe.saveContentBuilder(function (sHTML) {
          input.val(sHTML.replace(/\s\s+/g, ' '));
          iframe.addClass('saved');

          form.submit();
        });
      }
    });*/
  };

  /**
   * Change iframe width
   */
  var onChangeBreakpoint = function () {
    cbConfig.breakpointGroup.find('.btn').on('click', function () {
      "use strict";
      var _this = $(this);
      cbConfig.breakpointGroup.find('.btn').removeClass('active');
      _this.addClass('active');

      var breakpointName = _this.data('breakpoint');
      var width = cbConfig.breakpoints[breakpointName];
      if (typeof width === 'undefined') {
        width = '100%';
      } else {
        width = width + 'px';
      }
      iframe.css('width', width)
    });
  };

  /**
   * Bind fullscreen
   */
  var onClickFullScreen = function () {
    var btn = $('#cb-fullscreen_' + cbUniqueID);

    function requestFullScreen(element) {
      $('body').addClass('isCbFullscreen');
      element.addClass('isCbElementFullscreen');
    }

    var cancelFullScreen = function (element) {
      $('body').removeClass('isCbFullscreen');
      element.removeClass('isCbElementFullscreen');
      isFullscreen = false;
    };

    btn.on('click', function () {
      var _this = $(this);
      isFullscreen = _this.hasClass('active');

      if (isFullscreen) {
        _this.removeClass('active');
        cancelFullScreen(_this.closest('.content-builder-container'));
      } else {
        _this.addClass('active');
        requestFullScreen(_this.closest('.content-builder-container'));
      }
    });
  };

  //endregion

  /**
   * Initialize cb elements
   *
   * @param config
   * @returns {boolean}
   */
  var init = function (config) {
    "use strict";
    $.extend(cbConfig, config);

    if (typeof cbConfig['cbUniqueID'] === 'undefined' || cbConfig['cbUniqueID'].length <= 0) {
      console.error('No cbUniqueID pass.');
      return false;
    }

    cbUniqueID = cbConfig['cbUniqueID'];
    iframe = $('#iframe_' + cbUniqueID);
    contentBuilderIframe = iframe.get(0).contentWindow['ContentBuilderIframeBundle'];

    input = $('#' + iframe.data('id'));
    cbConfig['breakpointGroup'] = $('#cb-breakpoint-group_' + cbUniqueID);

    if (typeof input === 'undefined' || input.length <= 0) {
      alert('Not initialized ContentBuilder');
    }

    onBeforeSubmitForm();
    onChangeBreakpoint();
    onClickFullScreen();
  };

  return {
    init: init,
    setContentBuilderAutoHeight: setContentBuilderAutoHeight,
    iframeIsLoaded: iframeIsLoaded,
    triggerMediaManagerDialog: triggerMediaManagerDialog,
    triggerLinkableDialog: triggerLinkableDialog
  }
})();

$('[data-contentbuilder]').each(function() {
  ContentBuilderBundle.init($(this).data());
})


window.ContentBuilderBundle = global.ContentBuilderBundle = ContentBuilderBundle;
